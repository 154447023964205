








































import { formatRupiah } from "@/apps/core/modules/utils/text";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ShuListBox",
  props: {
    shu: { type: Object, required: true },
  },
  setup() {
    return {
      // Data
      formatRupiah,
    };
  },
});
